<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Archived Itineraries</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            dark
            :to="{
              name:
                tour.status === 'tour'
                  ? 'module-drum-tours-individual-itineraries'
                  : 'module-drum-proposals-individual-itineraries',
            }"
          >
            Return to active list
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="tour.archivedItineraries"
      no-data-text="There aren't any itineraries"
    >
      <template v-slot:item.title="{ item }">
        <router-link
          :to="{
            name:
              tour.status === 'tour'
                ? 'module-drum-tours-itinerary'
                : 'module-drum-proposals-itinerary',
            params: { itineraryId: item.id },
          }"
          >{{ item.title }}</router-link
        >
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ formatDateTime(item.updated_at, true) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          label
          small
          :color="
            item.status === 'Draft'
              ? 'blue'
              : item.status === 'Accepted'
              ? 'orange'
              : 'green'
          "
          text-color="white"
          >{{ item.status }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="green lighten-4 green--text"
              class="mr-2"
              v-on="on"
              :to="{
                name: 'module-drum-proposals-itinerary',
                params: { itineraryId: item.id },
              }"
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>View</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Last Updated", value: "formatted_dates.updated_at" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
  },

  methods: {
    getQuoteUrl(unique_id) {
      return process.env.VUE_APP_DRUMGOLF_URL + unique_id;
    },
  },
};
</script>
