var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Archived Itineraries")])]),_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{attrs:{"small":"","depressed":"","dark":"","to":{
            name:
              _vm.tour.status === 'tour'
                ? 'module-drum-tours-individual-itineraries'
                : 'module-drum-proposals-individual-itineraries',
          }}},[_vm._v(" Return to active list ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tour.archivedItineraries,"no-data-text":"There aren't any itineraries"},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name:
            _vm.tour.status === 'tour'
              ? 'module-drum-tours-itinerary'
              : 'module-drum-proposals-itinerary',
          params: { itineraryId: item.id },
        }}},[_vm._v(_vm._s(item.title))])]}},{key:"item.updated_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.updated_at, true))+" ")]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","small":"","color":item.status === 'Draft'
            ? 'blue'
            : item.status === 'Accepted'
            ? 'orange'
            : 'green',"text-color":"white"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"green lighten-4 green--text","to":{
              name: 'module-drum-proposals-itinerary',
              params: { itineraryId: item.id },
            }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }